import VersionedWidget from 'components/VersionedWidget/VersionedWidget';
import { useProduct } from 'hooks/useProduct';
import { render } from 'preact';
import logger from 'utils/logger';
import { config } from 'utils/config';
import styles from 'theme/base.module.css';
import { ConfigProvider, useConfigContext } from './context/ConfigContext';

const ThemedWidget = () => {
  const { product, theme } = useProduct();
  const { config: stateConfig } = useConfigContext();

  if (!product) {
    return null;
  }

  return (
    <div className={styles.common}>
      <VersionedWidget
        version={stateConfig.layoutVersion}
        style={theme}
        product={product}
        hideActiveIngredients={stateConfig.hideActiveIngredients}
        hideContaminants={stateConfig.hideContaminants}
      />
    </div>
  );
};

const App = () => {
  return (
    <ConfigProvider>
      <ThemedWidget />
    </ConfigProvider>
  );
};

const mountElement = document.getElementById(config.mountElementId);
if (!mountElement) {
  // For customers who have responsive designs with separate dom trees,
  // we need the ability to render the component is multiple places.
  //
  // Fallback to finding container elements using class names & rendering multiple times.
  logger.error('Falling back to class name elements:', config.mountElementId);
  const elements = document.getElementsByClassName(config.mountElementId);
  for (let el of elements) {
    render(<App />, el);
  }
} else {
  logger.info('Rendering to:', mountElement);
  render(<App />, mountElement);
}
